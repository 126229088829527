// Generated by Framer (d2b7042)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xxB9v6ZNo", "Q2U8WiNLF", "J6cQ8BGd5", "f7lPGyXm1", "HuKzRnxh4", "y9nckn3qv", "tXXJ55zrk", "HD9e5bdMB", "J5pHKs8tf", "WbuH0Dpao"];

const serializationHash = "framer-vucZm"

const variantClassNames = {f7lPGyXm1: "framer-v-1rauxd1", HD9e5bdMB: "framer-v-1hh6hm0", HuKzRnxh4: "framer-v-oislwd", J5pHKs8tf: "framer-v-1xded0d", J6cQ8BGd5: "framer-v-1v5tdsv", Q2U8WiNLF: "framer-v-1q9eka1", tXXJ55zrk: "framer-v-1v5zxoc", WbuH0Dpao: "framer-v-1nwtd4z", xxB9v6ZNo: "framer-v-1bq0288", y9nckn3qv: "framer-v-5to21g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}, f7lPGyXm1: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Image 1:1": "y9nckn3qv", "Image 16:9": "f7lPGyXm1", "Image 3:2": "J6cQ8BGd5", "Image 4:3": "Q2U8WiNLF", "Image 4:5": "HuKzRnxh4", "Variant 10": "WbuH0Dpao", "Variant 7": "tXXJ55zrk", "Variant 8": "HD9e5bdMB", "Variant 9": "J5pHKs8tf", Default: "xxB9v6ZNo"}

const getProps = ({height, id, image, mouseEnter, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "xxB9v6ZNo", vhrLqezAm: mouseEnter ?? props.vhrLqezAm, WKR34BYYJ: image ?? props.WKR34BYYJ} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};mouseEnter?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, WKR34BYYJ, vhrLqezAm, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "xxB9v6ZNo", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterslyzpm = activeVariantCallback(async (...args) => {
if (vhrLqezAm) {
const res = await vhrLqezAm(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", sizes: "min(256px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bq0288", className, classNames)} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"xxB9v6ZNo"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnterslyzpm} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({f7lPGyXm1: {"data-framer-name": "Image 16:9"}, HD9e5bdMB: {"data-framer-name": "Variant 8"}, HuKzRnxh4: {"data-framer-name": "Image 4:5"}, J5pHKs8tf: {"data-framer-name": "Variant 9"}, J6cQ8BGd5: {"data-framer-name": "Image 3:2"}, Q2U8WiNLF: {"data-framer-name": "Image 4:3"}, tXXJ55zrk: {"data-framer-name": "Variant 7"}, WbuH0Dpao: {"data-framer-name": "Variant 10", background: {alt: "", fit: "fill", sizes: "min(176px, 100vw)", ...toResponsiveImage(WKR34BYYJ)}}, y9nckn3qv: {"data-framer-name": "Image 1:1"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-vucZm[data-border=\"true\"]::after, .framer-vucZm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vucZm.framer-1ax6btc, .framer-vucZm .framer-1ax6btc { display: block; }", ".framer-vucZm.framer-1bq0288 { height: 256px; overflow: hidden; position: relative; width: 256px; }", ".framer-vucZm.framer-v-1q9eka1.framer-1bq0288 { aspect-ratio: 1.3333333333333333 / 1; height: var(--framer-aspect-ratio-supported, 192px); }", ".framer-vucZm.framer-v-1v5tdsv.framer-1bq0288 { aspect-ratio: 1.4970760233918128 / 1; height: var(--framer-aspect-ratio-supported, 171px); }", ".framer-vucZm.framer-v-1rauxd1.framer-1bq0288 { aspect-ratio: 1.7777777777777777 / 1; height: var(--framer-aspect-ratio-supported, 144px); }", ".framer-vucZm.framer-v-oislwd.framer-1bq0288 { aspect-ratio: 0.8 / 1; height: var(--framer-aspect-ratio-supported, 320px); }", ".framer-vucZm.framer-v-5to21g.framer-1bq0288 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 256px); }", ".framer-vucZm.framer-v-1v5zxoc.framer-1bq0288 { aspect-ratio: 2.56 / 1; height: var(--framer-aspect-ratio-supported, 100px); }", ".framer-vucZm.framer-v-1hh6hm0.framer-1bq0288 { aspect-ratio: 0.46545454545454545 / 1; height: var(--framer-aspect-ratio-supported, 550px); }", ".framer-vucZm.framer-v-1xded0d.framer-1bq0288 { aspect-ratio: 0.5688888888888889 / 1; height: var(--framer-aspect-ratio-supported, 450px); }", ".framer-vucZm.framer-v-1nwtd4z.framer-1bq0288 { aspect-ratio: 0.55 / 1; height: var(--framer-aspect-ratio-supported, 320px); width: 176px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 256
 * @framerIntrinsicWidth 256
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Q2U8WiNLF":{"layout":["fixed","fixed"]},"J6cQ8BGd5":{"layout":["fixed","fixed"]},"f7lPGyXm1":{"layout":["fixed","fixed"]},"HuKzRnxh4":{"layout":["fixed","fixed"]},"y9nckn3qv":{"layout":["fixed","fixed"]},"tXXJ55zrk":{"layout":["fixed","fixed"]},"HD9e5bdMB":{"layout":["fixed","fixed"]},"J5pHKs8tf":{"layout":["fixed","fixed"]},"WbuH0Dpao":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"WKR34BYYJ":"image","vhrLqezAm":"mouseEnter"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerf4VQ17SYz: React.ComponentType<Props> = withCSS(Component, css, "framer-vucZm") as typeof Component;
export default Framerf4VQ17SYz;

Framerf4VQ17SYz.displayName = "Image / Aspect Ratio Copy 5";

Framerf4VQ17SYz.defaultProps = {height: 256, width: 256};

addPropertyControls(Framerf4VQ17SYz, {variant: {options: ["xxB9v6ZNo", "Q2U8WiNLF", "J6cQ8BGd5", "f7lPGyXm1", "HuKzRnxh4", "y9nckn3qv", "tXXJ55zrk", "HD9e5bdMB", "J5pHKs8tf", "WbuH0Dpao"], optionTitles: ["Default", "Image 4:3", "Image 3:2", "Image 16:9", "Image 4:5", "Image 1:1", "Variant 7", "Variant 8", "Variant 9", "Variant 10"], title: "Variant", type: ControlType.Enum}, WKR34BYYJ: {title: "Image", type: ControlType.ResponsiveImage}, vhrLqezAm: {title: "Mouse Enter", type: ControlType.EventHandler}} as any)

addFonts(Framerf4VQ17SYz, [])